html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  border: none;
  margin: none;
  padding: none;
  box-shadow: none;
}

.nav-bar {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  background: black;
  color: lime;
  font-family: monospace;
  text-align: center;
  white-space: nowrap;
}
.nav-bar .project-link {
  height: 100%;
  flex: 1 1 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}
.nav-bar .project-link .selected-project {
  text-decoration: underline;
}
.nav-bar .project-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.project-display {
  width: 100vw;
  flex: 1 1 auto;
}
.project-display iframe {
  height: 100%;
  width: 100%;
}

.App {
  height: 100vh;
  width: 100vw;
  border: none;
  font-size: 13px;
  font-weight: lighter;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

