@use "colors";

.nav-bar {
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  background: black;
  color: lime;
  font-family: monospace;

  //overflow-x: scroll;
  text-align: center;
  white-space: nowrap;

  & .project-link {
    height: 100%;
    flex: 1 1 0px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 2px 5px;

    & .selected-project {
      text-decoration: underline;
      //font-style: italic;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
