@use "reset";
@use "colors";
@use "navbar";
@use "projectdisplay";

.App {
  height: 100vh;
  width: 100vw;
  border: none;

  font-size: 13px;
  font-weight: lighter;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
