@use "colors";

.project-display {
  width: 100vw;

  flex: 1 1 auto;

  & iframe {
    height: 100%;
    width: 100%;
  }
}
